// Popover
$(function () {
    $("[data-toggle=\"popover\"]").popover();
});

//Carousel
$(".carousel").carousel({
    interval:6000
});

$("body").on("click", "#carouselIndicators, #categoryCarousel, .carousel-inner", function() {
    $(".carousel").carousel("pause");
});

$(document).ready(function () {

    //CAROUSEL height adjust to be the same
    function carouselHeightsAdjust() {
        var items = $(".carousel-item"), //grab all slides
            heights = [], //create empty array to store height values
            tallest; //create variable to make note of the tallest slide

        function normalizeHeights() {
            items.each(function () { //add heights to array
                heights.push($(this).height());
            });
            tallest = Math.max.apply(null, heights); //cache largest value
            items.each(function () {
                $(this).css("min-height", tallest + "px");
            });
        }

        if (items.length) {
            normalizeHeights();

            $(window).on("resize orientationchange", function () {
                tallest = 0;
                heights.length = 0; //reset vars

                items.each(function () {
                    $(this).css("min-height", "0"); //reset min-height
                });
                normalizeHeights(); //run it again
            });
        }
    }

    carouselHeightsAdjust();

    //SPINNER ONLY FOR BASKET - does not have btn-up/down
    $("#basketItems input[name=quantity]").on("input", function () {
        $(this).parent().find(".refresh-btn").show();
    });
    $("#basketItems .spinner .btn").click(function (event) {
        var quantityInput = $(this).closest(".spinner").find("input[name=quantity]");
        var maxAvailable = $(this).closest(".spinner").data("max-quantity");
        var quantityValue = Math.abs(quantityInput.val());
        var formObject = $(this).closest("form");

        $(".spinner .btn").attr("disabled", false);

        formObject.attr("disabled", true);
        
        if (quantityValue > 0 && quantityValue <= maxAvailable) {
            formObject.find(".quantityError").hide();
        } else if (quantityValue > maxAvailable) {
            quantityInput.val(parseInt(maxAvailable, 10));
            quantityInput.attr("value", maxAvailable);
            formObject.find(".quantityError").show();
        }

        event.preventDefault();
        var form = $(this).closest("form");

        ajaxUpdateBasket(form);
        $(this).parent().find(".refresh-btn").hide();
    });

    //MODAL FOR ADD ITEM TO BASKET
    //MODAL FOR ADD ITEM TO BASKET

    $(".confirm-modal").click(function () {
        var button = $(this);
        button.attr("disabled", "disabled");
        $.ajax({url: $(this).data("confirm-modal-url")})
            .done(function (html) {
                $("#modal").html(html);
                $("#modal").modal("show");
                button.removeAttr("disabled", "disabled");
            });
            // .fail(function () {
            //     window.location.reload();
            // });
    });


    //button to go to the top
    //button to go to the top
    //button to go to the top
    $(window).scroll(function () {
        if ($(this).scrollTop() > 200) {
            $("#go-top").fadeIn(200);
            $("#basketB").fadeIn(200);
        } else {
            $("#go-top").fadeOut(200);
            $("#basketB").fadeOut(200);
        }
    });

    $("#go-top").click(function (event) {
        event.preventDefault();
        $("html, body").animate({scrollTop: 0}, 400);
    });

    //autocomplete SEARCH
    //autocomplete SEARCH
    //autocomplete SEARCH
    //autocomplete SEARCH

    $("#searchResults").on("click", ".showAllSearch", function () {
        $("form#searchForm").submit();
    });

    var timeout = null;
    $("#ajaxSearch").on("input", function () {
        clearTimeout(timeout);
        timeout = setTimeout(doAjaxCall, 1000);
    });

    $("body").click(function () {
        if (!$("#searchResults").hasClass("d-none")) {
            $("#searchResults").addClass("d-none");
        }
    });

    function doAjaxCall() {
        var data = $("#ajaxSearch").val();
        var searchLength = data.length;

        $.ajax({
            url: "/search/ajax",
            type: "GET",
            data: {"query": data},
            success: function (response) {
                if (response.success === "true" && searchLength >= 3) {
                    $("#searchResults").removeClass("d-none");
                    $("#searchResults").html(response.html);
                    $("#searchResults").animate({scrollTop: 0}, "fast");
                }
            }
        });
    }

    //LAST VISITED
    //LAST VISITED
    //LAST VISITED
    //LAST VISITED
    //LAST VISITED
    $("body").on("click", ".closeVisited", function() {
        var button = $(this);
        var itemId = button.data("item");

        $.ajax({
            type: "POST",
            url: "/item/clear/last-vsited?id=" + itemId,
            data: {"remove": true},
            dataType: "json",
            cache: false,
            success: function (response) {
                if (response.success === "true") {
                    button.closest(".card").hide();
                    if ($("#lastVisited .lastVisitedItem").not('[style]').length === 0) {
                        $("#lastVisited").hide();
                    }
                }
            }
        });
    });

    $("#clearLastVisited").click(function () {
        $.ajax({
            type: "POST",
            url: "/item/clear/last-vsited",
            data: {"remove": true},
            dataType: "json",
            cache: false,
            success: function (response) {
                if (response.success === "true") {
                    $("#lastVisited").hide();
                }
            }
        });
    });

    // COOKIE
    // COOKIE
    // COOKIE
    // COOKIE
    // COOKIE
    $("#cookie button").click(function () {
        $.ajax({
            type: "POST",
            url: "/cookie/agree",
            data: {"cookieAgree": true},
            dataType: "json",
            success: function (response) {
                if (response.success) {
                    $("#cookie").hide();
                }
            }
        });
    });

    //FILTERS EXTENDED ON MOBILE
    //FILTERS EXTENDED ON MOBILE
    //FILTERS EXTENDED ON MOBILE
    //FILTERS EXTENDED ON MOBILE
    //FILTERS EXTENDED ON MOBILE

    function findBootstrapEnvironment() {
        var envs = ["xs", "sm", "md", "lg"];
        var $el = $("<div>");
        $el.appendTo($("body"));
        for (var i = envs.length - 1; i >= 0; i--) {
            var env = envs[i];
            $el.addClass("hidden-" + env);
            if ($el.is(":hidden")) {
                $el.remove();
                return env;
            }
        }
    }

    var bootstrapEnv = findBootstrapEnvironment();
    if (bootstrapEnv === "xs") {
        $(".filters").removeClass("btn-group").removeClass("margin-right");
        $(".filters>div.btn-group").removeClass("pull-right");
        $(".filters>div.btn-group>a").addClass("btn-group-justified");
        $(".filters>div.btn-group button").addClass("btn-group-justified");
    }

    // BASKET STUFF
    // BASKET STUFF
    // BASKET STUFF
    // BASKET STUFF
    // BASKET STUFF
    // BASKET STUFF
    $("#countrySelector").change(function () {
        $("form#basket").submit();
    });
    $("input.deliveries").change(function () {
        var form = $(this).closest("form");
        $(".choiceList label").removeClass("active");
        $(this).parent("label").addClass("active");

        ajaxUpdateBasket(form);
    });

    function ajaxUpdateBasket(form) {
        $.ajax({
            type: "POST",
            url: "/basket/update/ajax",
            data: form.serialize(),
            success: function (response) {
                if (response.success === "true") {
                    var form = $(this).closest("form");
                    form.submit();

                    if (response.redirect === "true") {
                        window.location = "/basket/show";
                    }

                    $("#totalBasketPrice").text(response.total);
                    $("#itemsCount").text(response.itemsCount);
                    $("#totalPrice").text(response.totalWithDelivery);
                    $("#basket small span.itemsCount").text("(" + response.itemsCount + ") ");
                    $("#basket small span.hidden-sm").text(response.totalWithDelivery);
                    $("#basketDeliveryPrice").text(response.deliveryPrice);
                    if (response.deliveryTitle != null) {
                        $("#delTitle").text(response.deliveryTitle);
                    }
                    $("#voucherDiscount").text(response.voucherDiscount);

                    for (var i = 0, len = response.itemsArray.length; i < len; i++) {
                        var itemArray = response.itemsArray[i];

                        $("#basketItems").find("#item" + itemArray.id + " .itemPrice span").text(itemArray.itemPrice);
                        $("#basketItems").find("#item" + itemArray.id + " .itemTotal span").text(itemArray.itemSum);
                    }
                    for (var j = 0, len2 = response.deliveriesArray.length; j < len2; j++) {
                        var deliveriesArray = response.deliveriesArray[j];
                        $("label#delivery" + deliveriesArray.id + " span.deliveryVal").text(deliveriesArray.price);
                    }
                }
                
                $(".spinner .btn").attr("disabled", false);
            }
        });
    }

    var wto;

//THIS WAS TO UPDATE BASKET WHEN USER WAS CHANGING QUANTITY IN THE INPUT IN THE BASKET
    // $("#basketItems input[name=quantity]").on("change", function (event) {
    //     var formObject = $(this).closest("form");
    //     var quantityInput = $(this).closest(".spinner").find("input[name=quantity]");
    //     var maxAvailable = $(this).closest(".spinner").data("max-quantity");
    //     var quantityValue = parseInt(Math.abs(quantityInput.val()), 10);

    //     if (quantityValue < maxAvailable) {
    //         quantityInput.val(quantityValue);
    //         quantityInput.attr("value", quantityValue);
    //         formObject.find(".quantityError").hide();
    //     } else if (quantityValue > 0) {
    //         quantityInput.val(maxAvailable);
    //         quantityInput.attr("value", maxAvailable);
    //         formObject.find(".quantityError").show();
    //     } else {
    //         quantityInput.val(1);
    //         quantityInput.attr("value", 1);
    //     }

    //     event.preventDefault();
    //     var form = $(this).closest("form");
    //     console.log*("starting")

    //     clearTimeout(wto);

    //     if (quantityInput.val < 10) {
    //         wto = setTimeout(function() {
    //             ajaxUpdateBasket(form);
    //         }, 1000);
    //     }
    // });

    $("#paymentsSelection").on("click", ".radio", function(){
        $("#paymentsSelectionError").hide();
    });

    $("#deliverySelection").on("click", ".radio", function(){
        $("#selectDeliveryInfo").hide();
    });


    $(".paymentSubmitButton").attr("disabled", true);
    $(".paymentSimpleButton").attr("disabled", true);

    $(".paymentSubmitButton").click(function (event) {

        $("body").prepend("<div id=\"overlay\"></div>");
        $("body").prepend("<div id=\"pleaseWait\"><div class=\"loader\"></div></div>");


        var type = $(this).data("type");
        var path = "/basket/checkout/payment/ajax?type=" + type;

        event.preventDefault();

        if ($("#terms_accepted").prop("checked") && $("#terms_privacyPolicyAccepted").prop("checked")) {
            $("#overlay, #pleaseWait").show();
            $.ajax({
                type: "POST",
                url: path,
                data: {},
                dataType: "json",
                cache: false,
                success: function (response) {
                    if (response.success === "true") {
                        if (response.type === "paypal") {
                            var input = $("#orderDescription");
                            input.val("Order" + response.orderId + "-" + input.val());
                        }

                        $("#paymentForm-" + type).submit();
                    } else {
                        window.location = "/basket/summary?orderFailed=1";
                    }
                }
            });
        } else {
            $("#acceptTermsAlert").show();
        }

    });

    $("#terms_accepted").click(function () {
        if ($("#terms_accepted").prop("checked") && $("#terms_privacyPolicyAccepted").prop("checked")) {
            $(".paymentSubmitButton").attr("disabled", false);
            $(".paymentSimpleButton").attr("disabled", false);
            $("#acceptTermsAlert").hide();
        } else {
            $(".paymentSubmitButton").attr("disabled", true);
            $(".paymentSimpleButton").attr("disabled", true);
            $("#acceptTermsAlert").show();
        }
    });

    $("#terms_privacyPolicyAccepted").click(function () {
        if ($("#terms_accepted").prop("checked") && $("#terms_privacyPolicyAccepted").prop("checked")) {
            $(".paymentSubmitButton").attr("disabled", false);
            $(".paymentSimpleButton").attr("disabled", false);
            $("#acceptTermsAlert").hide();
        } else {
            $(".paymentSubmitButton").attr("disabled", true);
            $(".paymentSimpleButton").attr("disabled", true);
            $("#acceptTermsAlert").show();
        }
    });


    $("#btn-reg-info").click(function () {
        $("#more-info").toggle();
    });
    $("#invoiceShow").click(function () {
        $("#invoiceContainer").show();
        $(".invoiceHide").removeClass("active");
        $(".invoiceShow").addClass("active");
    });
    $("#invoiceHide").click(function () {
        $("#invoiceContainer").hide();
        $("#invoice_companyName").val("");
        $("#invoice_taxNumber").val("");
        $("#invoice_address1").val("");
        $("#invoice_city").val("");
        $("#invoice_postcode").val("");
        $(".invoiceShow").removeClass("active");
        $(".invoiceHide").addClass("active");
    });






    $(".accountReviewShowBtn").click(function () {
        $(this).parent().hide();
        $(this).parent().parent().children(".accountReviewDesc").show();
    });
    $(".accountReviewHideBtn").click(function () {
        $(this).parent().hide();
        $(this).parent().parent().children(".accountShortReviewDesc").show();
    });



    //ENDPOLIO
    if ($("#userForm_additional_rotarian").prop("checked")) {
        $("#clubFields").show();
    } else {
        $("#clubFields").hide();
    }

    $("#userForm_additional_rotarian").change(function () {
        if ($("#userForm_additional_rotarian").prop("checked")) {
            $("#clubFields").show();
        } else {
            $("#clubFields").hide();
        }
    });


    // LOGIN
    //googleAuthentication
    $("#login #username").focusout(function () {
        var data = $("#username").val();
        $.ajax({
            url: "/login/ajax",
            type: "POST",
            data: {"_username": data},
            success: function (response) {
                if (response.success === "true") {
                    var html="<div><input class=\"form-control\" type=\"text\" id=\"form_authentication\" name=\"_authentication_token\" autocomplete=\"false\"></div>";
                    $("#authenticator").html(html);
                } else {
                    $("#authenticator").html("");
                }
            }
        });
    });
});